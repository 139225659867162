<template>
    <div
        class="border rounded border-primary d-flex flex-column px-2 py-2 w-64"
    >
        <span class="h5 text-capitalize"> {{ input.format }}</span>
        <div class="d-flex justify-content-between">
            <span class="font-weight-bold"> Fecha</span>
            <span> {{ input.date }}</span>
        </div>
        <div class="d-flex justify-content-between">
            <span class="font-weight-bold"> Hora</span>
            <span> {{ input.hour }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "DateDeliveryViewer",
    props: {
        input: {
            default: () => ({}),
            type: Object,
            require: true
        }
    }
};
</script>
