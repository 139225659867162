:
<template>
    <div class="w-100">
        <div
            v-for="(item, index) in itemsCurrent"
            :key="index"
            class="border-bottom border-info"
        >
            <div class="d-flex justify-content-between my-4">
                <div>
                    <div
                        v-if="item.cityCode === 'default'"
                        class="d-flex flex-column"
                    >
                        <strong> Configuración por defecto </strong>
                        <small>
                            Aplica para las ciudades no configuradas
                        </small>
                    </div>
                    <strong v-else>
                        {{ item.cityCode | city }}
                    </strong>
                </div>
                <td v-if="enabled">
                    <div class="d-flex justify-content-center">
                        <button
                            v-if="item.cityCode !== 'default'"
                            class="btn btn-danger mx-2 len-3 d-flex align-items-center justify-content-center"
                            @click="deleteItem(index)"
                        >
                            🗑️
                        </button>
                        <button
                            class="btn btn-secondary mx-2 len-3 d-flex align-items-center justify-content-center"
                            @click="editItem(index)"
                        >
                            🖊
                        </button>
                    </div>
                </td>
            </div>
            <delivery-configuration-by-units-viewer
                :items="item.configuration"
            />
        </div>

        <div v-if="enabled" class="d-flex justify-content-center mt-4">
            <vs-tooltip bottom border>
                <button
                    v-if="enabled"
                    class="border border-secondary btn rounded-circle d-flex justify-content-center align-items-center p-2"
                    @click="openNewItem"
                >
                    <span>➕</span>
                </button>
                <template #tooltip> Nueva configuración</template>
            </vs-tooltip>
        </div>

        <div>
            <vs-dialog
                v-model="isOpenModalSelectItem"
                scroll
                prevent-close
                overflow-hidden
                @close="onClose"
            >
                <template #header>
                    <span class="h3">
                        Configuración de cargos y tiempos de entrega por ciudad
                    </span>
                </template>
                <div class="con-content py-2 px-4">
                    <div class="d-flex flex-wrap">
                        <div class="col-11 mx-auto">
                            <city-code-selector
                                v-if="itemTemp.cityCode !== 'default'"
                                v-model="itemTemp.cityCode"
                                class="mt-3"
                                @change="validateDuplicate"
                            />
                            <div v-else class="d-flex flex-column">
                                <strong> Configuración por defecto. </strong>
                                <small>
                                    Aplica para las ciudades no configuradas
                                </small>
                            </div>
                        </div>
                        <div class="col-12 mt-4">
                            <strong>
                                Tabla de configuración de cargos y tiempos de
                                entrega por unidades
                            </strong>
                            <delivery-configuration-by-units-editor
                                v-model="itemTemp.configuration"
                            />
                        </div>
                        <div v-if="errorMessage" class="mx-3 mt-4">
                            <span class="h4 text-danger">
                                {{ errorMessage }}
                            </span>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-3">
                        <vs-button
                            class="mt-3 mb-5 col-9 mx-auto"
                            border
                            gradient
                            @click="pushOrUpdateItem"
                        >
                            <span class="h4">
                                {{
                                    isEditingItem
                                        ? "Actualizar configuración ✔️"
                                        : "Agregar configuración ✔️"
                                }}
                            </span>
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";
import DeliveryConfigurationByUnitsEditor from "@/components/utils/DeliveryConfigurationByUnitsEditor.vue";
import DeliveryConfigurationByUnitsViewer from "@/components/utils/DeliveryConfigurationByUnitsViewer.vue";

export default {
    name: "DeliveryConfigurationsByCityManage",
    components: {
        CityCodeSelector,
        DeliveryConfigurationByUnitsViewer,
        DeliveryConfigurationByUnitsEditor
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        enabled: {
            default: () => true,
            required: false,
            type: Boolean
        },
        itemsCurrent: {
            default: () => [
                {
                    cityCode: "11001",
                    configuration: [
                        { deliveryHours: 0, deliveryPrice: 0, min: 1 }
                    ]
                }
            ],
            required: true,
            type: Array
        }
    },
    emits: ["change"],
    data: () => ({
        isOpenModalSelectItem: false,
        itemTemp: {
            index: 0,
            cityCode: "11001",
            configuration: [{ deliveryHours: 0, deliveryPrice: 0, min: 1 }]
        },
        errorMessage: "",
        isEditingItem: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark"]),
        isInvalidCurrentItem() {
            return this.itemTemp.cityCode === "" || this.errorMessage;
        }
    },
    watch: {},
    mounted() {},
    methods: {
        onClose() {
            this.errorMessage = "";
        },
        validateDuplicate() {
            if (this.isEditingItem) {
                return true;
            }
            const showDuplicate = this.itemsCurrent.some(
                (item) => item.cityCode === this.itemTemp.cityCode
            );
            if (showDuplicate) {
                this.errorMessage = `Ya tienes una configuración para la ciudad ${this.$options.filters.city(
                    this.itemTemp.cityCode
                )}`;
                return false;
            }
            this.errorMessage = "";
            return true;
        },
        editItem(fromIndex) {
            this.isEditingItem = true;
            let arrAux = [...this.itemsCurrent];
            this.itemTemp = { index: fromIndex, ...arrAux[fromIndex] };
            this.isOpenModalSelectItem = true;
        },
        deleteItem(fromIndex) {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.splice(fromIndex, 1);
            this.updateItems(itemsResult);
        },
        openNewItem() {
            this.itemTemp = {
                configuration: [
                    { min: 1, deliveryHours: 1, deliveryPrice: 1000 }
                ],
                cityCode: ""
            };
            this.isOpenModalSelectItem = true;
        },
        updateItems(items = []) {
            const result = items.sort((a, b) => {
                if (a.cityCode === "default") return -1;
                if (b.cityCode === "default") return 1;
                return a.cityCode.localeCompare(b.cityCode);
            });
            this.$emit("change", result);
        },
        pushOrUpdateItem() {
            if (!this.validateData()) {
                return;
            }
            const itemsAux = [...this.itemsCurrent];
            if (this.isEditingItem) {
                itemsAux[this.itemTemp.index] = {
                    cityCode: this.itemTemp.cityCode,
                    configuration: this.itemTemp.configuration
                };
                this.isEditingItem = false;
            } else {
                itemsAux.push(this.itemTemp);
            }
            this.isOpenModalSelectItem = false;
            this.updateItems(itemsAux);
        },
        validateData() {
            if (!this.itemTemp.cityCode) {
                this.errorMessage =
                    "Selecciona una ciudad en la parte superior";
                return false;
            }
            return this.validateDuplicate();
        }
    }
};
</script>
