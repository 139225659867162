<template>
    <div>
        <table class="table" :class="tableDark">
            <thead>
                <tr>
                    <th scope="col">Unidades</th>
                    <th scope="col">Horas adicionales</th>
                    <th scope="col">Costo adicional</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(item, index) in items"
                    :key="`delivery-config-${id}-${index}`"
                >
                    <td class="col-4">
                        <span>
                            {{ getRangeMessage(item, index) }}
                        </span>
                    </td>
                    <td class="col-4">
                        <span>
                            {{ item.deliveryHours }}
                        </span>
                    </td>
                    <td class="col-4">
                        <span>
                            {{ item.deliveryPrice | currency }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DeliveryConfigurationByUnitsViewer",
    props: {
        items: {
            default: () => [],
            required: true,
            type: Array
        },
        id: {
            default: () => "id",
            required: false,
            type: String
        }
    },
    data: () => ({}),
    computed: {
        ...mapGetters("control", ["tableDark"])
    },
    methods: {
        getRangeMessage(item, index) {
            const min = item.min;
            if (index === this.items.length - 1) {
                return `Más de ${min}`;
            }
            const max = this.items.at(index + 1).min - 1;
            if (min == max) {
                return `Para ${min}`;
            }
            return `Entre ${min} - ${max}`;
        }
    }
};
</script>
