<template>
    <div class="d-flex flex-wrap my-3">
        <div class="col-6 col-md-3 d-flex flex-column">
            <label for="hours" class="my-2">Minutos</label>
            <b-form-spinbutton
                id="hours"
                v-model="minutes"
                class="mx-2"
                max="59"
                min="0"
                size="sm"
                inline
                :disabled="!enabled"
                @change="onInput"
            >
                <template #decrement>➖</template>
                <template #increment>➕</template>
            </b-form-spinbutton>
        </div>

        <div class="col-6 col-md-3 d-flex flex-column">
            <label for="hours" class="my-2">Horas</label>
            <b-form-spinbutton
                v-model="hours"
                class="mx-2"
                max="24"
                min="0"
                size="sm"
                inline
                :disabled="!enabled"
                @change="onInput"
                ><template #decrement>➖</template>
                <template #increment>➕</template>
            </b-form-spinbutton>
        </div>
        <div class="col-6 col-md-3 d-flex flex-column">
            <label for="hours" class="my-2">Días</label>
            <b-form-spinbutton
                v-model="days"
                class="mx-2"
                max="30"
                min="0"
                size="sm"
                inline
                :disabled="!enabled"
                @change="onInput"
                ><template #decrement>➖</template>
                <template #increment>➕</template>
            </b-form-spinbutton>
        </div>

        <div class="col-6 col-md-3 d-flex flex-column">
            <label for="hours" class="my-2">Meses</label>
            <b-form-spinbutton
                v-model="months"
                class="mx-2"
                max="12"
                min="0"
                size="sm"
                inline
                :disabled="!enabled"
                @change="onInput"
                ><template #decrement>➖</template>
                <template #increment>➕</template>
            </b-form-spinbutton>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrepareTimeManager",
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => ({
                minutes: 0,
                hours: 1,
                days: 0,
                months: 0
            }),
            type: Object,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        minutes: 0,
        hours: 1,
        days: 0,
        months: 0
    }),
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        setInput(value) {
            this.minutes = value.minutes;
            this.months = value.months;
            this.hours = value.hours;
            this.days = value.days;
        },
        onInput() {
            this.$emit("change", {
                minutes: this.minutes,
                months: this.months,
                hours: this.hours,
                days: this.days
            });
        }
    }
};
</script>
