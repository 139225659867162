<template>
    <div class="w-100 border mt-2">
        <table class="table table-hover table-bordered m-0 p-0">
            <thead>
                <tr class="table-primary">
                    <th class="col-4 p-0">
                        <div
                            class="d-flex justify-content-center align-items-center table-height-80"
                        >
                            <span>Unidades mínimas </span>
                        </div>
                    </th>
                    <th class="col-4 p-0">
                        <div
                            class="d-flex justify-content-center align-items-center table-height-80"
                        >
                            <span>Horas de entrega adicional</span>
                        </div>
                    </th>
                    <th class="col-4 p-0">
                        <div
                            class="d-flex justify-content-center align-items-center table-height-80"
                        >
                            <span>Costo adicional de entrega</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in itemsTemp" :key="item.min">
                    <th class="text-center font-weight-normal">
                        <vs-input
                            v-if="item.min !== 1"
                            id="min_"
                            v-model="item.min"
                            class="w-100 bg-base text-base vs-input-custom"
                            type="number"
                            border
                            shadow
                            autocomplete="off"
                            state="primary"
                            min="2"
                        />
                        <span v-else class="text-base">
                            1 <small> * Valor requerido </small>
                        </span>
                    </th>
                    <th class="text-center font-weight-normal">
                        <vs-input
                            id="deliveryHours_"
                            v-model="item.deliveryHours"
                            class="w-100 bg-base text-base vs-input-custom"
                            type="number"
                            min="0"
                            border
                            shadow
                            autocomplete="off"
                            state="primary"
                            @blur="onInput"
                        />
                    </th>
                    <th>
                        <input-money-component
                            v-model="item.deliveryPrice"
                            enabled
                            border
                            class="w-100 bg-base text-base"
                            label=""
                            @blur="onInput"
                        />
                    </th>
                    <th class="p-0 m-0">
                        <button
                            v-if="item.min !== 1"
                            class="btn d-flex p-0 h-20 align-items-center justify-content-center"
                            @click="deleteItemFromIndex(index)"
                        >
                            <small> ❌ </small>
                        </button>
                    </th>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-center" colspan="2">
            <vs-tooltip bottom border>
                <vs-button icon border @click="addNewItem"> ➕ </vs-button>
                <template #tooltip> Nuevo rango</template>
            </vs-tooltip>
        </div>
    </div>
</template>

<script>
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";

export default {
    name: "DeliveryConfigurationByUnitsEditor",
    components: { InputMoneyComponent },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [{ max: 1, deliveryPrice: 1000, deliveryHours: 1 }],
            required: true
        }
    },
    emits: ["change"],
    data: () => ({
        itemsTemp: []
    }),
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemsCurrent);
    },
    methods: {
        addNewItem() {
            const item = this.itemsTemp.at(-1);
            this.itemsTemp.push({
                min: Number(item.min) + 1,
                deliveryPrice: item.deliveryPrice * 2,
                deliveryHours: item.deliveryHours * 2
            });
            this.$emit("change", this.itemsTemp);
        },
        deleteItemFromIndex(fromIndex) {
            this.itemsTemp.splice(fromIndex, 1);
            this.onInput();
        },
        setInput(value) {
            this.itemsTemp = [...value];
        },
        onInput() {
            this.$emit("change", this.itemsTemp);
        }
    }
};
</script>
